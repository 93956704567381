// This will be one of the themed pre-selected groups within the carousel above the fold as the user enters the sphere
import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { philosophers } from '../../PhilosophersData';
import { ChosenPhilosophersContext } from '../../context/ChosenPhilosophersContext';
import ConfirmationModal from '../ConfirmationModal';
import MemoizedPhilosopherImage from './MemoizedPhilosopherImages';


//Import pictures
import ImmanuelKant from '../../images/marbleStatuePictures/FrontFacing/ImmanuelKantWhiteBG.jpg';
import MaryMidgley from '../../images/marbleStatuePictures/FrontFacing/MaryMidgleyWhiteBG.jpg';
import PhilippaFoot from '../../images/marbleStatuePictures/FrontFacing/PhilippaFootWhiteBG.jpg';
import JohnStuartMill from '../../images/marbleStatuePictures/FrontFacing/JohnStuartMillWhiteBG.jpg';
import White_chat_bubble from '../../images/icons/White_chat_bubble.svg';

// Import videos
import ImmanuelKantVideo from '../../videoes/ImmanuelKant.mp4';
import MaryMidgleyVideo from '../../videoes/MaryMidgley.mp4';
import PhilippaFootVideo from '../../videoes/PhilippaFoot.mp4';
import JohnStuartMillVideo from '../../videoes/JohnStuartMill.mp4';

const PathwaysToAHappyLife = ({ setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, currentThemeIndex, setAutoPlayOnPause, setPreMadeQuestions }) => {
  const { setSelectedPhilosophers, selectedCount} = useContext(ChosenPhilosophersContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  
  // States for playing the videoes by change of CSS style from image to video in the case that the videos have been fully loaded and is ready to play seemlessly
  const [isImmanuelKantVideoReady, setImmanuelKantVideoReady] = useState(false);
  const [isMaryMidgleyVideoReady, setMaryMidgleyVideoReady] = useState(false);
  const [isPhilippaFootVideoReady, setPhilippaFootVideoReady] = useState(false);
  const [isJohnStuartMillVideoReady, setJohnStuartMillVideoReady] = useState(false);
  const [isPageVisible, setPageVisible] = useState(true);

  // Refs to access videos elements as means to play them from the start when a user go's to a new theme in the theme carousel
  const kantVideoRef = useRef(null);
  const midgleyVideoRef = useRef(null);
  const footVideoRef = useRef(null);
  const millVideoRef = useRef(null);

  // Variable for the modal to show if one wants to chat with these instead of what was allready in the basket
  const name = 'Immanuel Kant, Mary Midgley, Philippa Foot and John Stuart Mill'

  // Variable that initiates the philosophers on the topic of the conversation
  const prompt = "Please, give the visitor of PhiloSphere a personal greeting fitting for your character. Then introduce the theme of Ethics and Morality and how you have experienced and worked with that as a topic. Then end by asking what questions related to the topic they are pondering, if they are looking for advice or maybe would like to discuss some hypothetical ethical dilemmas. Answer in English until you've been told otherwise"

  // Variable for the questions that should be selectable when this themed chat is chosen
  const themeQuestions = [
    "What do each of you think are the greatest ethical misconceptions that persist in society today?",
    "Kant, can moral duties ever conflict, and if so, how should we resolve such conflicts?",
    "Do you believe that modern society's focus on individualism has eroded our sense of moral duty towards animals and the environment?",
    "Foot, is the concept of moral virtue still relevant in today's fast-paced, technology-driven world?",
    "Mill, is the pursuit of happiness truly the highest moral aim, or should we prioritize other values like justice or equality?",
    "In a world with rapidly advancing technology, what new moral dilemmas do you foresee arising, and how should we address them?",
    "Can there ever be a universal moral code, or are morals inherently subjective and culturally dependent?",
    "Kant, does your categorical imperative still apply in a world where artificial intelligence might soon make decisions for us?",
    "Midgley, how can we reframe the relationship between humans and nature to foster a more ethical coexistence?",
    "Foot, how can we ensure that moral judgments based on 'natural goodness' still hold weight in an age of biotechnology and genetic modification?",
    "Mill, does utilitarianism adequately address the complexities of modern-day inequality and systemic injustice?",
    "Is it morally justifiable to prioritize human advancement over the well-being of other species and the planet?",
    "How should we navigate ethical challenges that arise from global inequalities, such as access to resources, healthcare, and technology?",
    "Looking forward, what ethical principles should guide humanity as we encounter new frontiers in space exploration, biotechnology, and artificial intelligence?",
    // ...other questions
];


  const themedGroup = useMemo(() => [
    philosophers.find(p => p.name === 'Immanuel Kant'),
    philosophers.find(p => p.name === 'Mary Midgley'),
    philosophers.find(p => p.name === 'Philippa Foot'),
    philosophers.find(p => p.name === 'John Stuart Mill')
  ],[])

  const handleStartConverseClick = () => {
    if (selectedCount > 0) {
      setIsConfirmationModalOpen(true);
      setAutoPlayOnPause(true)
    } else {
      setPreMadeQuestions(themeQuestions)
      setIsPhilosophersResponding(true);
      setCurrentSpeakerIndex(0);
      setSelectedPhilosophers(themedGroup)
      setIsPhilosopherCatalogVisible(false)
      setCurrentUserQuestion(prompt);

      // Ensure selectedPhilosophers is updated before setting the sequence
      setTimeout(() => {
        const updatedSelectedPhilosophers = themedGroup;
        setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
      }, 100);
      setIsQuestionsOpen(true)
    }
  }

  const handleConfirm = () => {
    setPreMadeQuestions(themeQuestions)
    setIsPhilosophersResponding(true);
    setCurrentSpeakerIndex(0);
    setSelectedPhilosophers(themedGroup)
    setIsPhilosopherCatalogVisible(false)
    setCurrentUserQuestion(prompt);

    // Ensure selectedPhilosophers is updated before setting the sequence
    setTimeout(() => {
      const updatedSelectedPhilosophers = themedGroup;
      setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
    }, 100);
    setAutoPlayOnPause(false)
    setIsQuestionsOpen(true)
    setIsConfirmationModalOpen(false)
  }

  const handleCancel = () => {
    setAutoPlayOnPause(false)
    setIsConfirmationModalOpen(false)
  }

  // Function to pause videos when page is not visible
  const pauseVideos = () => {
    if (kantVideoRef.current) kantVideoRef.current.pause();
    if (midgleyVideoRef.current) midgleyVideoRef.current.pause();
    if (footVideoRef.current) footVideoRef.current.pause();
    if (millVideoRef.current) millVideoRef.current.pause();
  };

  // Function to play videos from the start when they are ready
  const playVideosFromStart = () => {
    if (isImmanuelKantVideoReady && kantVideoRef.current) {
      kantVideoRef.current.currentTime = 0;
      kantVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isMaryMidgleyVideoReady && midgleyVideoRef.current) {
      midgleyVideoRef.current.currentTime = 0;
      midgleyVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isPhilippaFootVideoReady && footVideoRef.current) {
      footVideoRef.current.currentTime = 0;
      footVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isJohnStuartMillVideoReady && millVideoRef.current) {
      millVideoRef.current.currentTime = 0;
      millVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPageVisible(false);
        pauseVideos();
      } else {
        setPageVisible(true);
        playVideosFromStart();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isImmanuelKantVideoReady, isMaryMidgleyVideoReady, isPhilippaFootVideoReady, isJohnStuartMillVideoReady]);

  useEffect(() => {
    if (currentThemeIndex === 4 && isPageVisible) {
      playVideosFromStart();
    }
  }, [currentThemeIndex, isPageVisible, isImmanuelKantVideoReady, isMaryMidgleyVideoReady, isPhilippaFootVideoReady, isJohnStuartMillVideoReady]);

  return (
    <div className='Carousel-theme'>
      <h2>Ethics and Morality, Past to Future</h2>
      <div className='Philosophers-in-theme'>
        <div className='Group1'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={ImmanuelKant} alt="Immanuel Kant" style={{ display: isImmanuelKantVideoReady ? 'none' : 'block' }} />
            <video
              ref={kantVideoRef}
              src={ImmanuelKantVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setImmanuelKantVideoReady(true);
              }}
              style={{ display: isImmanuelKantVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Immanuel Kant</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={MaryMidgley} alt="Mary Midgley" style={{ display: isMaryMidgleyVideoReady ? 'none' : 'block' }}/>
            <video
              ref={midgleyVideoRef}
              src={MaryMidgleyVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setMaryMidgleyVideoReady(true);
              }}
              style={{ display: isMaryMidgleyVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Mary Midgley</h3>
          </div>
        </div>
        <div className='Group2'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={PhilippaFoot} alt="Philippa Foot" style={{ display: isPhilippaFootVideoReady ? 'none' : 'block' }} />
            <video
              ref={footVideoRef}
              src={PhilippaFootVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setPhilippaFootVideoReady(true);
              }}
              style={{ display: isPhilippaFootVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Philippa Foot</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={JohnStuartMill} alt="John Stuart Mill" style={{ display: isJohnStuartMillVideoReady ? 'none' : 'block' }} />
            <video
              ref={millVideoRef}
              src={JohnStuartMillVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setJohnStuartMillVideoReady(true);
              }}
              style={{ display: isJohnStuartMillVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>John Stuart Mill</h3>
          </div>
        </div>
      </div>
      <p className='Theme-description' id='Theme-description'> Engage with thinkers that evolved the field of ethics and learn how their teachings can help navigate the complexities of today’s moral landscape.</p>
      <button className='Start-conversation-button' onClick={handleStartConverseClick}> <img src={White_chat_bubble} /> Start Conversation </button>
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        philosopherName={name}
      />
    </div>
  );
};

export default PathwaysToAHappyLife;