// components/QuestionsSection.js
import React, { useEffect, useState, useContext } from 'react';
import '../App.css';
import { ChosenPhilosophersContext } from '../context/ChosenPhilosophersContext';
import { createqgmpChain } from '../AIs/questionsGeneratorMultiplePhilosophers';
import { createqgspChain } from '../AIs/questionsGeneratorSinglePhilosopher';

const QuestionsSection = ({ onQuestionSelect, isVisible, shouldNewQuestionsBeSet, preMadeQuestions, shouldCardsDissable, apiKey}) => {

const [questions, setQuestions] = useState([]);
const { selectedPhilosophers, selectedCount } = useContext(ChosenPhilosophersContext);

//function to generate questions
const generateQuestions = async () => {
  let generatedQuestions = [];
  let chainFunction = selectedCount === 1 ? createqgspChain : createqgmpChain;
  const philosopherList = selectedPhilosophers.map((philosopher, index) => `${index + 1}. ${philosopher.name}`).join('\n');
  
  for (let i = 0; i < 15; i++) {
    const qgChain = chainFunction(apiKey, philosopherList);
    const question = await qgChain.invoke({ questionsPreviouslyGenerated: generatedQuestions.join(", ") });
    generatedQuestions.push(question);
    
    // Update the questions state after each question is generated
    setQuestions(prevQuestions => [...prevQuestions, question]);

    // Introduce a small delay to simulate the step-by-step rendering of questions
    await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay
  }
};

useEffect(() => {
  if (shouldNewQuestionsBeSet) {
    if (preMadeQuestions) {
      setQuestions(preMadeQuestions)
    } else {
      setQuestions([])
      // Generate questions for user-selected philosophers
      generateQuestions();
    }
  }
}, [shouldNewQuestionsBeSet])



  return (
    <div className={`Questions-section-${isVisible ? 'visible' : 'hidden'}`}>
        <h2>Questions</h2>
        <div className='Q-cards-section'>
            <div className='Info-line'>
              <span>i</span>
              <p>Click on a question to ask it</p>
            </div>
            <div className='Clickable-questions'>
              {questions.map((question, index) => (
                <div key={index} className={`Question-card ${shouldCardsDissable ? 'disabled' : ''}`} onClick={() => onQuestionSelect(question)} tabindex="0" role="button">
                    {question}
                </div>
              ))}
            </div>
        </div>
    </div>
  );
};

export default QuestionsSection;
