/**
 * This promptTemplate is meant for generating questions for a conversation with a single philosopher
 * When @const {qgChain}. is invoked with LangChain, OpenAI recieves a prompt from the user and generates questions for the user based of the users selected philosophers.
 * @param {String} questionsPreviouslyGenerated - formatted string representing with the questions that have allready been generated.
 */

import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { RunnableSequence } from "@langchain/core/runnables";
import { createGpt35Turbo } from "../utils/gpt35Turbo";

const createqgspChain = (apiKey, philosopherList ) => {
  const gpt35Turbo = createGpt35Turbo(apiKey);


  const template = `You are a philosophic question generator helping a user write thought-provoking questions to ask ${philosopherList}
    
    Users can select the questions you generate by clicking on them and that way to explore this philosopher's most profound ideas.
    You will generate questions one at a time and always take into account the question(s) you've asked before so that you never repeat the same question but instead generate a variety of deeply interesting questions with different relevant topics for the philosopher at hand.
    
    Here are some specifics you will follow:
      1. Keep your questions super short, precise and easy to understand following ISO 24495-1 Plain Language, for a user who is completely new to philosophy.
      2. Because the user is talking directly to this philosopher, don't mention the philosopher in third person or by name in your questions.
      3. Make the first question a slightly provocative one triggering the user on what are some misconceptions most people live with, not appealing to any of the philosophers, but simply asking them openly.
    
    The questions you've already generated are here: "{questionsPreviouslyGenerated}"

    Please generate your next question:`;

  const prompt = PromptTemplate.fromTemplate(template);

  return RunnableSequence.from([
    prompt,
    gpt35Turbo,
    new StringOutputParser()
  ]);
};

export { createqgspChain };
