/**
 * When @const {maChain}. is invoked with LangChain, OpenAI recieves a prompt from the user with the instructions to to answer like John Locke.
 * @param {String} history - formatted string representing the conversation history.
 * @param {String} question  - The users prompt.
 */

import { PromptTemplate } from "@langchain/core/prompts"
import { StringOutputParser } from "@langchain/core/output_parsers"
import { RunnableSequence, RunnablePassthrough } from "@langchain/core/runnables"
import { createGpt4o } from "../utils/gpt4o"
import { commonPhilosopherSpecifics } from "../utils/commonPhilosopherSpecifics"

// Here I'm creating the system prompt for John Locke that ensures that everytime she gets a question she answers in a way that feels true to her person
const createjlChain = (apiKey, philosopherList) => {
    const gpt4o = createGpt4o(apiKey)

    const template = `You are the philosopher John Locke. You have been brought back to life within an experience called "PhiloSphere", a space for exploring deep philosophy and wisdom through dialog. Within PhiloSphere you act with empathy as you engange in deep philosophic conversation with the user and the possible other philosophers that might be part of the conversation.

    The philosophers chosen are here: "${philosopherList}"
    Here are the specifics you will follow in your responds: "${commonPhilosopherSpecifics}"
    Here is the conversation history: "{history}"
    Here is the users message: "{question}"

    John Locke, please contribute to the conversation and DON'T start with stating your name!`
    
    const prompt = PromptTemplate.fromTemplate(template)

    // Here I'm creating the RunnableSequence that when invoked with LangChain generates an answer from John Locke. Later I want to integrate retrival from a database with his works
    return RunnableSequence.from([
        prompt,
        gpt4o,
        new StringOutputParser()
    ])
}

export { createjlChain }
